<template>
  <div>
    <div
      v-if="mobile"
      id="journal-cards"
    >
      <v-card
        v-for="(item, idx) in itemsModified"
        :key="`card-${idx}`"
        height="150"
        class="d-flex mb-3 pa-1"
        :class="getItemClass(item)"
      >
        <v-row>
          <v-col>
            <JournalUser
              :item="item"
              :user-id="userId"
            />
          </v-col>
          <v-col cols="4">
            {{ item.transit_procedure }}
          </v-col>
          <v-col cols="3">
            {{ item.id }}
          </v-col>
          <v-col>
            <JournalActions
              :item="item"
              @perform="onPerform"
            />
          </v-col>
          <v-col class="py-0">
            {{ item.out_number }}
          </v-col>
          <v-col class="py-0">
            {{ item.regnums }}
          </v-col>
          <v-col cols="12">
            <journal-item-e-c-l-i-e-n-t :item="item" />
          </v-col>
        </v-row>
      </v-card>
      <v-data-footer
        :pagination="cardsPagination"
        :options="options"
        :items-per-page-options="[15, 30, 50]"
        :show-first-last-page="false"
        @update:options="onCardsOptsUpdate"
      />
    </div>
    <v-data-table
      v-else
      class="rounded-t-0 border-t-0"
      :items="itemsModified"
      :headers="headers"
      disable-sort
      :item-class="getItemClass"
      :options="options"
      :server-items-length="total"
      :footer-props="{
        'items-per-page-options': [15, 30, 50],
        'show-first-last-page': false,
      }"
      @update:items-per-page="setPaginationSize"
      @update:page="setPaginationPage"
    >
      <template #[`item.declarant`]="{ item }">
        <JournalUser
          :item="item"
          :user-id="userId"
        />
      </template>
      <template #[`item.status`]="{ item }">
        <journal-item-n-c-e-s :item="item" />
      </template>
      <template #[`item.actions`]="{ item }">
        <JournalActions
          :item="item"
          @perform="onPerform"
        />
      </template>
    </v-data-table>
    <component
      :is="activeComponent"
      :id="selectedId"
      :ref="getReferenceAsync"
      :root-user-id="rootUserId"
      :show.sync="showComponent"
      module="sez"
    />
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {capitalize} from "@/helpers/text";
import JournalItemECLIENT from "@/components/journal/journal-item-eclient.vue";
import {methods, actions} from "./functions";
import JournalItemNCES from "@/components/journal/journal-item-nces.vue";
import JournalTableMixin from "@/mixins/journal-table.mixin";
import {getReferenceAsync} from "@/helpers/async-component";
import moment from "moment";

export default {
  components: {
    JournalItemNCES,
    JournalItemECLIENT,
  },
  mixins:[JournalTableMixin],
  data: () => ({
    selected:null,
    namespace:"sez",
    asyncDecision: {
      resolve:null,
      reject:null
    },
    selectedPto:null,
    actions,
    headers: [
      {
        text: "",
        value: "declarant",
      },
      {
        text: "ID",
        value: "id",
      },
      {
        text: "ДТ GUID",
        value: "dt_guid",
      },
      {
        text: "Исх.номер",
        value: "out_number",
      },
      {
        text: "Дата подачи",
        value: "departure_date_normalized",
      },
      {
        text: "Код ПТО",
        value: "custom_code",
      },
      {
        text: "Статус",
        value: "status",
      },
      {
        text: "Кол-во товаров",
        value: "ware_quantity",
      },
      {
        text: "",
        value: "actions",
      },
    ],
  }),
  computed: {
    ...mapGetters({
      items: "journal/getItems",
      total: "journal/totalCount",
      pagination: "journal/pagination",
      user: "auth/getUser",
    }),
    itemsModified() {
      return this.items.map((item) => {
        const {
          dt_guid,
          unique_key,
          last_name,
          first_name,
          phone,
          email,
          id,
          out_number,
          user_id,
          eclient_request_id,
          status = "",
          ecd_request_id,
          is_deferred,
          is_trash,
          transit_procedure,
          carrier_name,
          principal_name,
          client_division_id,
          custom_code,
          ware_quantity,
          departure_date
        } = item;

        const isRequest = !!(client_division_id && true && user_id === null);


        const initials = this.getInitials({first_name, last_name});
        const fullname = this.getFullName({first_name, last_name});
        const departure_date_normalized = moment(departure_date).isValid()
          ? moment(departure_date).format('DD.MM.YYYY HH:mm:ss')
          : null
        let status_capitalized = "";
        if (status) {
          const [first, ...words] = status ? status.split(" ") : [];
          status_capitalized = [capitalize(first), ...words].join(" ");
        }
        const actions = this.getItemActions(item);
        return {
          actions, // меню с действиями
          last_name,
          first_name,
          phone,
          email,
          id,
          initials,
          user_id,
          eclient_request_id,
          fullname,
          carrier_name,
          principal_name,
          ecd_request_id,
          is_deferred,
          transit_procedure,
          status: status_capitalized,
          is_trash,
          isRequest,
          dt_guid,
          unique_key,
          out_number,
          custom_code,
          ware_quantity,
          departure_date,
          departure_date_normalized
        };
      });
    },
  },
  watch:{
    showComponent(value){
      if(!value) this.unsetAsyncComponent()
    },
  },
  methods: {
    getReferenceAsync,
    ...methods, // методы для каждого элемента
    getDocumentTypeActions(status) {
      const actions = [...this.actions];
      if (status.myRequestDocument) {
        const available = ["downloadPdf", "downloadPdfDT", "addDocuments"];
        return actions.filter((i) => {
          return available.includes(i?.action) || i === null;
        });
      } else if (status.isRequest) {
        return [];
      } else if (status.documentFromRequest) {
        const config = {
          downloadPdf: ["doc.div.pdf.create"],
          changeRegnum: ["doc.div.pdf.create"],
          changeDeferredStatus: ["doc.div.pdf.create"],
          downloadDocumentsArhive: ["doc.div.pdf.create"],
          copyDocument: ["doc.div.copy.create"],
          changePerformer: ["doc.priv.user.update"],
          downloadXml: ["doc.priv.xml.create"],
          deleteDocument: ["doc.priv.delete"],
          fillGuid:["doc.div.pdf.create"],
          sendDocument:["doc.div.pdf.create"]
        };
        // Аннулирование и корректировка только для оформленных документов (есть регномер) + оформленных и скорректированных
        if (status.isFramed && !status.isAnnuled) {
          Object.assign(config, {
            adjustDocument: ["doc.div.copy.create"],
            annulDocument: ["doc.div.copy.create"],
          });
        }
        return this.modifyActions(this.getAvailableActions(config), status);
      } else if (status.myDocument) {
        const config = {
          downloadPdf: ["doc.div.pdf.create"],
          copyDocument: ["doc.div.copy.create"],
          changePerformer: ["doc.priv.user.update"],
          downloadXml: ["doc.priv.xml.create"],
          deleteDocument: ["doc.priv.delete"],
          fillGuid:["doc.div.pdf.create"],
          sendDocument:["doc.div.pdf.create"]
        };
        if (status.isFramed && !status.isAnnuled) {
          Object.assign(config, {
            adjustDocument: ["doc.div.copy.create"],
            annulDocument: ["doc.div.copy.create"],
          });
        }
        return this.modifyActions(this.getAvailableActions(config), status);
      } else if (!status.myDocument) {
        const config = {
          downloadPdf: ["doc.div.pdf.create"],
          copyDocument: ["doc.div.copy.create"],
          changePerformer: ["doc.div.user.update"],
          downloadXml: ["doc.div.xml.create"],
        };
        return this.modifyActions(this.getAvailableActions(config), status);
      } else {
        return [];
      }
    },
    modifyActions(actions, status) {
      if (!status) return actions;
      return actions.map((i) => {
        if (i?.action === "deleteDocument" && status.isTrash) {
          return {
            ...i,
            icon: "mdi-delete-off",
            name: "Восстановить",
          };
        } else if (i?.action === "changeDeferredStatus" && status.isDeffered) {
          return {
            ...i,
            icon: "mdi-play",
            name: "Возобновить",
          };
        }
        return i;
      });
    },
    showDocument(event, {item}) {
      if (
        this.mobile || // Просмотр только на десктопе
          (this.userId !== null && item.user_id !== this.userId) || // Доступ только к собственным декларациям
          (item.client_division_id && item.user_id === null) // Если заявка
      )
        return false;
      const route = this.$router.resolve({
        path: `/transit/${item.id}`,
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>

<style scoped>
.isApplication {
  background-color: blue !important;
}

.v-data-footer__select {
  display: block;
}
</style>
