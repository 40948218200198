import {
  downloadXml,
  getFilenameFromContentDisposition,
  downloadFile,
  getXmlString,
  getSignedDocument,
  sendToNces,
  validateRequiredParams,
  NAMESPACE_REQUIRED,
  USER_REQUIRED,
  updateSelectedPto, PTO_REQUIRED,
} from '@/helpers/documents'
import {waitAsyncDecision, waitResultOrDie} from "@/helpers/async-component";

const actions = [
  {
    icon: "mdi-tray-arrow-down",
    name: "Скачать xml",
    divider: true,
    action: "downloadXml"
  },
  {
    icon:"mdi-identifier",
    name:"Заполнить GUID",
    action:"fillGuid",
    divider:true,
    component:() => import('@/components/documents/fill-guid.vue')
  },
  {
    icon:"mdi-upload",
    name:"Отправить в ИСТО",
    action:"sendDocument",
  },
  {
    icon: "",
    action: "showDocumentControlPto",
    name: "Выбор ПТО",
    component: () => import("@/components/shared/document-control-pto.vue"),
  },
]

const methods = {
  waitAsyncDecision,
  waitResultOrDie,
  unsetAsyncComponent(){
    this.activeAction = null
  },
  getActionIndex(actionId){
    const action = this.actions.find(i => i?.action === actionId)
    return this.actions.indexOf(action)
  },
  downloadXml({id}){
    this.$store.dispatch("sez/getXml", id).then((res) => {
      downloadXml(res.data, getFilenameFromContentDisposition(res))
    });
  },
  downloadPdf({id}){
    this.$store.dispatch('sez/downloadPdf', id).then((res) => {
      const name = getFilenameFromContentDisposition(res)
      downloadFile({data:res.data, type:'application/pdf', name})
    }).catch(() => this.$error('Ошибка'))
  },
  copyDocument({id, actionId}){
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  deleteDocument({id, item}){
    const value = !item.is_trash
    this.$store.dispatch('sez/toTrash',{id, value} ).then(() => {
      this.$snackbar({text:"Документ удален",color:"green", top:false, right: false})
      this.$store.dispatch('journal/fetchItems')
    }).catch(() => this.$error('Ошибка'))
  },
  changePerformer({id, actionId}){
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  fillGuid({id, actionId}){
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  showDocumentControlPto(){
    this.activeAction = this.getActionIndex("showDocumentControlPto");
    this.showComponent = true;
    return Promise.resolve()
      .then(this.waitAsyncDecision)
      .then(this.waitResultOrDie)
  },
  checkGuid(){
    if(!this.selected.dt_guid){
      this.$error("Отсутствует GUID")
      return Promise.reject()
    }
    return Promise.resolve()
  },
  getXmlString,
  getSignedDocument,
  sendToNces,
  validateRequiredParams,
  updateSelectedPto,
  sendDocument({id, item}){
    this.selectedId = id
    this.selected = item
    this.validateRequiredParams({
      ...NAMESPACE_REQUIRED,
      ...USER_REQUIRED,
      ...PTO_REQUIRED,
    })
      .then(this.checkGuid)
      .then(this.showDocumentControlPto)
      .then(this.updateSelectedPto)
      .then(this.getXmlString)
      .then(this.getSignedDocument)
      .then(this.sendToNces)
      .catch((e) => {console.log(e)})
  },
}
export {
  methods, actions
}
